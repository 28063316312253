import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/services/core/IApp';

@Component({
  selector: 'admin-profile-form',
  templateUrl: './admin-profile-form.component.html',
  styleUrls: ['./admin-profile-form.component.scss'],
})
export class AdminProfileFormComponent {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @Input() user: User | any;

  accountForm: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.compose([Validators.required])),
    lastName: new FormControl('', Validators.compose([Validators.required])),
    email: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    ),
    avatar: new FormControl('', Validators.compose([Validators.required])),
  });

  modalId: string = 'changeAvatarModal';

  uploadedImage: string = '';

  constructor() {}

  ngOnChanges() {
    if (this.user) {
      this.accountForm.patchValue({
        ...this.user,
        firstName: this.user.name.first,
        lastName: this.user.name.last,
      });

      if (this.user.avatar) {
        this.uploadedImage = this.user.avatar;
      }
    }
  }

  ngOnDestroy() {
    this.accountForm.reset({
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    });
  }

  submitProfile() {
    if (this.accountForm.valid) {
      this.onSubmit.emit(this.accountForm.value);
    }
  }

  setRoles(roles: string) {
    this.accountForm.patchValue({
      role: roles,
    });
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  setAvatar(avatar: string) {
    this.uploadedImage = avatar;
    this.accountForm.patchValue({
      avatar: avatar,
    });
  }

  uploadProfileImage(event: any) {
    console.log('here we go oo');
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadedImage = reader.result as string;
    };
    this.accountForm.patchValue({
      avatar: file,
    });
  }

  get firstName() {
    return this.accountForm.get('firstName') as FormControl;
  }

  get lastName() {
    return this.accountForm.get('lastName') as FormControl;
  }

  get email() {
    return this.accountForm.get('email') as FormControl;
  }

  // get role() {
  //   return this.accountForm.get('role') as FormControl;
  // }
}
