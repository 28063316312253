import { Injectable } from '@angular/core';
import { User } from '../../core/IApp';
import { GlobalsService } from '../../core/globals';
import { RequestService } from '../../core/request';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  user: User | undefined;
  users: User[] = [];
  usersPagination = {
    itemsPerPage: 5,
    totalItemsCount: 0,
    page: 1,
    search: '',
    sort: 'createdAt',
    order: 'desc',
  };

  constructor(private api: RequestService, private globals: GlobalsService) {}

  async getUser() {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get('user');
        this.user = resp.message as User;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err);
        reject(err);
      }
    });
  }

  async getUserById(id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.get(`admin-users/${id}`);
        this.user = resp.data as User;
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err);
        reject(err);
      }
    });
  }

  async getAllUsers() {
    return await new Promise(async (resolve, reject) => {
      try {
        const { message, data }: any = await this.api.get(
          `admin-users?page=${this.usersPagination.page}&itemsPerPage=${this.usersPagination.itemsPerPage}&sort=${this.usersPagination.sort}&order=${this.usersPagination.order}&search=${this.usersPagination.search}`
        );
        this.users = data.users as User[];
        this.usersPagination.totalItemsCount = data.totalItemsCount;
        resolve(message);
      } catch (err: any) {
        this.globals.spinner.hide();
        reject(err);
      }
    });
  }

  async createUser(user: User) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post('auth/register', user);
        if (resp.message) this.globals.toast.success(resp.message);
        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err);
        reject(err);
      }
    });
  }

  async updateUser(_id: string, user: User | any, isupdateAvatar: boolean) {
    console.log('we got here 5', user);
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        this.api.headers = {
          'content-type': 'multipart/form-data',
        };
        const formData = new FormData();
        isupdateAvatar && formData.append('avatar', user.avatar);
        formData.append('firstName', user.firstName);
        formData.append('lastName', user.lastName);
        formData.append('email', user.email);
        !isupdateAvatar && formData.append('role', user.role);

        console.log(this.api.headers);

        const resp: any = await this.api.update(`admin-users/${_id}`, formData);
        if (resp.message) this.globals.toast.success(resp.message);
        formData.forEach((value, key) => {
          console.log(`${key}:`, value);
        });

        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err?.message || err);
        reject(err);
      }
    });
  }

  async deleteUser(_id: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show(); // Show a loading spinner
        const resp: any = await this.api.delete(`admin-users/${_id}`); // Send DELETE request
        if (resp.message) this.globals.toast.success(resp.message); // Show success message
        this.globals.spinner.hide(); // Hide the spinner
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide(); // Hide the spinner on error
        this.globals.toast.error(err?.message || err); // Show error message
        reject(err);
      }
    });
  }

  async forgotUser(email: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.globals.spinner.show();
        const resp: any = await this.api.post('auth/forget-password', {
          email,
        });
        if (resp.message) this.globals.toast.success(resp.message);

        this.globals.spinner.hide();
        resolve(resp);
      } catch (err: any) {
        this.globals.spinner.hide();
        this.globals.toast.error(err);
        reject(err);
      }
    });
  }
}
